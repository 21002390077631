<template>
  <span class="made-with-love">
    Made with <span>❤️</span> in Ireland
  </span>
</template>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.made-with-love {
  font-size: 1.1rem;

  &>span {
    animation: pulse 4s infinite;
    display: inline-block;
  }
}
</style>
